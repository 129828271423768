import * as React from "react"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {graphql} from "gatsby"
import FaqPage from "src/components/common/faqPage";

const Faq0005 = () => {
  const {t} = useTranslation();
  return (
    <FaqPage title={t("faq.removeAndRestore.question")}>
      <p>{t("faq.removeAndRestore.answer.item1")}</p>
      <ul>
        <li>{t("faq.removeAndRestore.answer.item2.item1")}</li>
        <li>{t("faq.removeAndRestore.answer.item2.item2")}</li>
      </ul>
      <p>{t("faq.removeAndRestore.answer.item3")}</p>
      <p>{t("faq.removeAndRestore.answer.item4")}</p>
    </FaqPage>

  );
}

export default Faq0005

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
